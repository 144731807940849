import React from "react";
import { View } from "../View";
import { OverlayFieldset } from "../../routes/AgentBranch/components/Fieldset/OverlayFieldset";
import { MenuButton } from "../../routes/AgentBranch/components/Button/MenuButton";
import { Text } from "../Text";
import { SectionDivider } from "../../routes/AgentBranch/components/SectionDivider/SectionDivider";
import { Link } from "../Link";
import { PageTitle } from "../../routes/AgentBranch/components/Text/PageTitle";
import { colors, EVENTS } from "../../constants";

function SupportModal({ closeOverlay, analytics }) {
  return (
    <View
      css={{
        lineHeight: "140%",
        paddingBottom: 48,
      }}
    >
      <View
        css={{
          display: "flex",
          justifyContent: "flex-end",
          height: 50,
          alignItems: "center",
        }}
      >
        <MenuButton opened={true} onClick={closeOverlay} />
      </View>
      <View>
        <PageTitle title="Need support?" />
      </View>
      <View>
        <Text color={colors.lightMidGrey} size={15}>
          Check out our frequently asked questions or contact us by email or
          phone.
        </Text>
      </View>
      <View css={{ marginTop: 24 }}>
        <OverlayFieldset legend="FAQs">
          <View>
            <Text color={colors.lightMidGrey} size={15}>
              Self-help resource
            </Text>
            <View css={{ marginTop: 12, color: colors.white }}>
              <Text size={15}>
                <Link
                  href="https://earnnest.com/resources/faq"
                  target="_blank"
                  onClick={() => {
                    analytics.event({
                      ...EVENTS.Global.ContactSupport,
                      label: "FAQ",
                    });
                  }}
                >
                  Find an answer
                </Link>
              </Text>
            </View>
          </View>
        </OverlayFieldset>
      </View>
      <View css={{ marginTop: 24 }}>
        <SectionDivider />
      </View>
      <View css={{ marginTop: 24 }}>
        <OverlayFieldset legend="Email">
          <View>
            <Text color={colors.lightMidGrey} size={15}>
              Send us an email and we'll get back to you within one (1) business
              day.
            </Text>
            <View css={{ marginTop: 12, color: colors.white }}>
              <Text size={15}>
                <Link
                  href="mailto:support@earnnest.com"
                  target="_blank"
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = "mailto:support@earnnest.com";
                    analytics.event({
                      ...EVENTS.Global.ContactSupport,
                      label: "Email Support",
                    });
                  }}
                >
                  support@earnnest.com
                </Link>
              </Text>
            </View>
          </View>
        </OverlayFieldset>
      </View>
      <View css={{ marginTop: 24 }}>
        <SectionDivider />
      </View>
      <View css={{ marginTop: 24 }}>
        <OverlayFieldset legend="Phone">
          <View>
            <Text color={colors.lightMidGrey} size={15}>
              Monday - Friday
              <br />8 a.m. to 8 p.m. EST
            </Text>
            <View css={{ marginTop: 12, color: colors.white }}>
              <Text size={15}>
                <Link
                  href="tel:+1-888-870-2336"
                  target="_blank"
                  onClick={e => {
                    e.preventDefault();
                    window.location.href = "tel:+1-888-870-2336";
                    analytics.event({
                      ...EVENTS.Global.ContactSupport,
                      label: "Phone Support",
                    });
                  }}
                >
                  1-888-870-2336
                </Link>
              </Text>
            </View>
          </View>
        </OverlayFieldset>
      </View>
    </View>
  );
}

export { SupportModal };
