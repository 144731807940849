import { padding, rgba } from "polished";
import React from "react";
import ReactDOM from "react-dom";
import { Manager, Popper, Reference } from "react-popper";
import { config, Transition } from "react-spring-legacy";
import { DualHover } from "../../../../components/Powerplug";
import { COLORS } from "../../../../constants";
import { View } from "../View";
import { AnimatedView } from "../View/AnimatedView";
import { Tooltip } from "./Tooltip";
import { ColorSchemeContext } from "../../../AppBranch/ColorScheme";

function TooltipTransitioner({ popperPlacement, children }) {
  // We need to render the child in a hidden element for the popper
  // to pick up the dimensions, so that it can set proper popper placement
  if (!popperPlacement) {
    return <View css={{ opacity: 0 }}>{children}</View>;
  }

  const transformAtRest = {
    ...(popperPlacement === "right" && {
      transform: "translateX(-4px)",
    }),
    ...(popperPlacement === "left" && {
      transform: "translateX(4px)",
    }),
    ...(popperPlacement === "bottom-start" && {
      transform: "translateY(-4px)",
    }),
    ...(popperPlacement === "top-start" && {
      transform: "translateY(4px)",
    }),
  };

  return (
    <Transition
      native={true}
      items={children}
      config={config.stiff}
      from={{
        opacity: 0,
        ...transformAtRest,
      }}
      enter={{
        opacity: 1,
        ...((popperPlacement === "right" || popperPlacement === "left") && {
          transform: "translateX(0)",
        }),
        ...((popperPlacement === "bottom-start" ||
          popperPlacement === "top-start") && {
          transform: "translateY(0)",
        }),
      }}
      leave={{
        opacity: 0,
        ...transformAtRest,
      }}
    >
      {item =>
        item && (props => <AnimatedView style={props}>{item}</AnimatedView>)
      }
    </Transition>
  );
}

class TooltipPopper extends React.Component {
  static contextType = ColorSchemeContext;
  touched = false;
  state = { clicked: false, ref: null };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  handleClick = e => {
    if (this.node.contains(e.target) || this.node === e.target) {
      return;
    }
    this.setState(prevState => {
      return { ...prevState, clicked: false };
    });
  };
  render() {
    const {
      disableClick = false,
      disabled = false,
      children,
      text,
      pad,
      placement = "bottom-start",
      mode = this.context.colorScheme,
    } = this.props;
    const { clicked } = this.state;
    const computedPadding = pad || padding(8);
    return (
      <Manager>
        <DualHover>
          {({ hovered, hovers, binds }) => (
            <React.Fragment>
              <Reference>
                {({ ref }) => {
                  return (
                    <View
                      tag={disableClick ? "span" : "button"}
                      type={disableClick ? undefined : "button"}
                      css={{
                        appearance: "none",
                        background: "none",
                        border: "none",
                        display: "inline-flex",
                        ...computedPadding,
                        margin: 0,
                        borderRadius: 4,
                        outline: "none",
                        "&:focus": {
                          boxShadow: `0 0 0 .15em ${rgba(
                            COLORS.neueGreen,
                            0.5,
                          )}`,
                        },
                      }}
                      onTouchStart={() => {
                        if (this.touchEndTimeout) {
                          clearTimeout(this.touchEndTimeout);
                        }
                        this.touched = true;
                      }}
                      onTouchEnd={() => {
                        this.touchEndTimeout = setTimeout(() => {
                          this.touched = false;
                        }, 100);
                      }}
                      onMouseEnter={() => {
                        if (this.touched) {
                          return;
                        }
                        binds[0].onMouseEnter();
                      }}
                      onMouseLeave={() => {
                        binds[0].onMouseLeave();
                        this.touched = false;
                      }}
                      onClick={() => {
                        if (disableClick) {
                          return;
                        }
                        this.setState(prevState => ({
                          ...prevState,
                          clicked: !prevState.clicked,
                        }));
                      }}
                      onBlur={() => {
                        if (disableClick) {
                          return;
                        }
                        this.setState({ clicked: false });
                      }}
                      ref={node => (this.node = node)}
                    >
                      <View css={{ display: "inline-flex" }} ref={ref}>
                        {children}
                      </View>
                    </View>
                  );
                }}
              </Reference>
              {ReactDOM.createPortal(
                <Popper
                  placement={placement}
                  positionFixed={true}
                  modifiers={{
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: "viewport",
                    },
                  }}
                >
                  {({ ref, style, placement: popperPlacement, arrowProps }) => (
                    <View
                      css={{
                        pointerEvents: hovered || clicked ? "auto" : "none",
                      }}
                    >
                      <TooltipTransitioner popperPlacement={popperPlacement}>
                        {!disabled && (hovered || clicked) && (
                          <View
                            ref={ref}
                            style={{
                              ...(popperPlacement === "bottom-start" && {
                                paddingTop: 8,
                              }),
                              ...(popperPlacement === "top-start" && {
                                paddingBottom: 8,
                              }),
                              ...(popperPlacement === "right" && {
                                paddingLeft: 10,
                              }),
                              ...(popperPlacement === "left" && {
                                paddingRight: 10,
                              }),
                              ...style,
                            }}
                            data-placement={popperPlacement}
                            onMouseEnter={() => {
                              if (hovers[0]) {
                                binds[1].onMouseEnter();
                              }
                            }}
                            onMouseLeave={() => {
                              binds[1].onMouseLeave();
                            }}
                          >
                            <Tooltip
                              mode={mode}
                              text={text}
                              placement={popperPlacement}
                              arrowRef={arrowProps.ref}
                              arrowStyle={arrowProps.style}
                            />
                          </View>
                        )}
                      </TooltipTransitioner>
                    </View>
                  )}
                </Popper>,
                document.querySelector("#tooltip"),
              )}
            </React.Fragment>
          )}
        </DualHover>
      </Manager>
    );
  }
}

export { TooltipPopper };
