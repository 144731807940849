import React from "react";
import { padding, rgba, transitions } from "polished";
import { View } from "../components/View";
import { COLORS } from "../constants";
import { ActivityIndicator } from "../icons/ActivityIndicator";
import { ColorSchemes, useColorScheme } from "../routes/AppBranch/ColorScheme";

const Config = {
  [ColorSchemes.DARK]: {
    backgroundColor: COLORS.neueGreen,
    disabledBackgroundColor: COLORS.mammonButtonUIDarkDisabled,
    disabledTextColor: COLORS.mammonInputUILightDisabled,
  },
  [ColorSchemes.LIGHT]: {
    backgroundColor: COLORS.darkPurple,
    disabledBackgroundColor: COLORS.mammonInputUILightDisabled,
    disabledTextColor: COLORS.white,
  },
};

function MediumButton({
  acting,
  disabled,
  width = "auto",
  minWidth = 190,
  children,
  ...restProps
}) {
  const { colorScheme } = useColorScheme();
  const colorSchemeConfig = Config[colorScheme];
  const fillColor = disabled
    ? colorSchemeConfig.disabledBackgroundColor
    : colorSchemeConfig.backgroundColor;
  return (
    <View
      tag="button"
      type="button"
      css={{
        appearance: "none",
        display: "inline-flex",
        justifyContent: "center",
        border: 0,
        ...padding(6, 16),
        margin: 0,
        backgroundColor: fillColor,
        borderWidth: 2,
        borderColor: fillColor,
        borderStyle: "solid",
        borderRadius: 999,
        width,
        minWidth,
        fontSize: 16,
        lineHeight: 16 / 16,
        color: "white",
        textAlign: "center",
        textDecoration: "none",
        cursor: disabled ? "not-allowed" : "pointer",
        outline: "none",
        ...transitions(
          ["background-color", "border-color", "box-shadow"],
          "0.15s ease-out",
        ),
        "&:focus": {
          boxShadow: `0 0 0 .15em ${rgba(fillColor, 0.5)}`,
        },
      }}
      disabled={disabled}
      {...restProps}
    >
      {acting ? (
        <View css={{ ...padding(2, null) }}>
          <ActivityIndicator color="white" size={48} />
        </View>
      ) : (
        children
      )}
    </View>
  );
}

export { MediumButton };
