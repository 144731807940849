import { useMemo } from "react";
import { checkForBrowserFeatures } from "../utilities/checkForBrowserFeatures";

function useCheckForSupportedBrowser(features) {
  const allTestsPass = useMemo(() => {
    return checkForBrowserFeatures(features);
  }, [features]);

  return allTestsPass;
}

export { useCheckForSupportedBrowser };
