import React from "react";

const RATIO = 8 / 11;

class NavTriangle extends React.Component {
  render() {
    const { size = 11, color } = this.props;
    return (
      <svg
        width={size * RATIO}
        height={size}
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.2113 4.61275L7.97446 0L7.97446 10.8778L1.2113 6.26503C0.629245 5.86805 0.629247 5.00973 1.2113 4.61275Z"
          fill={color}
        />
      </svg>
    );
  }
}

export { NavTriangle };
