import React from "react";
import { View } from "../../components/View";
import { Text } from "../../components/Text";
import { EarnnestRingedLogoWordmark } from "./EarnnestRingedLogoWordmark";
import { MediumButton } from "../../kudzu/MediumButton";
import { Link } from "../../components/Router";

function StatusCodeScreenTemplate({
  logo = true,
  subtitle,
  title,
  description,
  home = true,
}) {
  return (
    <View
      css={{
        display: "flex",
        flexDirection: "column",
        padding: 24,
      }}
    >
      <View
        css={{
          margin: "auto",
          display: "flex",
          flex: "1 0 auto",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          maxWidth: 640,
        }}
      >
        {logo && (
          <View css={{ marginBottom: 48, display: "inline-flex" }}>
            <EarnnestRingedLogoWordmark />
          </View>
        )}
        <View css={{ color: "#000" }}>
          <View>
            <Text
              family="Unna"
              size={32}
              fontStyle="italic"
              line="120%"
              spacing="-0.02em"
              weight="700"
            >
              {title}
            </Text>
          </View>
          <View css={{ marginTop: 24 }}>
            {subtitle && (
              <View css={{ marginBottom: 8 }}>
                <Text size={20} line="140%" weight="500">
                  {subtitle}
                </Text>
              </View>
            )}
            <Text line="160%" family="Ubuntu">
              {description}
            </Text>
          </View>
          {home && (
            <View css={{ marginTop: 48 }}>
              {home === true ? (
                <MediumButton tag={Link} to={{ pathname: "/" }} minWidth={160}>
                  Home
                </MediumButton>
              ) : (
                home
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export { StatusCodeScreenTemplate };
