import React from "react";

class InfoIcon extends React.Component {
  render() {
    const { size = 16, color = "#ABABAB" } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="7.5" stroke={color} />
        <circle r="1" transform="matrix(-1 0 0 1 8 5)" fill={color} />
        <rect
          width="2"
          height="5"
          rx="1"
          transform="matrix(-1 0 0 1 9 7)"
          fill={color}
        />
      </svg>
    );
  }
}

export { InfoIcon };
