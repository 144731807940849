import { getEnv } from "./env";

export function initialize(opts = {}) {
  const env = getEnv();

  window.ga("create", {
    trackingId: env.REACT_APP_GA_TRACKER,
    cookieDomain: "auto",
    ...opts,
  });

  // Tries to use network.sendBeacon in browsers that support it.
  // This is more reliable than using typical network request
  window.ga("set", "transport", "beacon");

  console.log("Analytics service initialized [%s].", env.REACT_APP_GA_TRACKER);
}

export function set(opts = {}) {
  window.ga("set", opts);
  console.log("Setting options on analytics service.", opts);
}

export function unset(opts = []) {
  const toUnset = opts.reduce((obj, key) => {
    return { ...obj, [key]: null };
  }, {});
  window.ga("set", toUnset);
  console.log("Unsetting options on analytics service.", toUnset);
}

export function send(opts = {}) {
  const { hitType, ...restOpts } = opts;
  window.ga("send", opts);
  console.log("Sending %s to analytics service.", hitType, restOpts);
}

// Denotes to GA that a pageview occurred.
//
// title  text  no  The title of the page (e.g. homepage)
// page   text  no  The path portion of a URL. This value should start with a slash (/) character.
export function pageview(pageObj = {}, opts = {}) {
  // page should be equivalent to location.pathname
  const { location, page, title } = pageObj;

  // Set the page and title on the tracker instance so it persists on other hits
  set({ location, page, title });

  // Finally, send the hit to GA
  send({ hitType: "pageview", ...opts });
}

// Sends event information to GA
//
// eventCategory  text     yes  Typically the object that was interacted with (e.g. 'Video')
// eventAction    text     yes  The type of interaction (e.g. 'play')
// eventLabel     text     no   Useful for categorizing events (e.g. 'Fall Campaign')
// eventValue     integer  no   A numeric value associated with the event (e.g. 42)
export function event(eventObj = {}, opts = {}) {
  const { category, action, label, value, nonInteraction = false } = eventObj;
  send({
    hitType: "event",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
    nonInteraction,
    ...opts,
  });
}

// Sends timing information to GA
//
// timingCategory  text    yes  A string for categorizing all user timing variables into logical groups (e.g. 'JS Dependencies').
// timingVar       text    yes  A string to identify the variable being recorded (e.g. 'load').
// timingValue     integer yes  The number of milliseconds in elapsed time to report to Google Analytics (e.g. 20).
// timingLabel     text    no   A string that can be used to add flexibility in visualizing user timings in the reports (e.g. 'Google CDN').
export function timing(timingObj = {}, opts = {}) {
  const { category, var: timingVar, value, label } = timingObj;
  send({
    hitType: "timing",
    timingCategory: category,
    timingVar,
    timingValue: value,
    timingLabel: label,
    ...opts,
  });
}

// Sends exception information to GA.
//
// exDescription  text     no  A description of the exception.
// exFatal        boolean  no  true if the exception was fatal.
export function exception(error, isFatal = false) {
  send({
    hitType: "exception",
    exDescription: error.message,
    exFatal: isFatal,
  });
}
