import React from "react";
import { css as emotion, cx } from "emotion";
import { shallowEqual } from "../../utilities/shallowEqual";
import { changeFavicon } from "../../utilities/domHelpers";

let stack = [];

class Page extends React.Component {
  constructor(...args) {
    super(...args);

    const { children: _children, ...attrs } = this.props;
    this.attrs = attrs;
    stack = [...stack, this.attrs];
  }

  componentDidMount() {
    this.apply();
  }

  componentDidUpdate(prevProps) {
    const { children: _children, ...attrs } = this.props;
    const { children: _prevChildren, ...prevAttrs } = prevProps;
    if (!shallowEqual(attrs, prevAttrs)) {
      stack = stack.map(s => {
        if (s === this.attrs) {
          return attrs;
        }
        return s;
      });
      this.attrs = attrs;
      this.apply();
    }
  }

  componentWillUnmount() {
    stack = stack.filter(s => s !== this.attrs);
    this.attrs = null;
    this.apply();
  }

  render() {
    return this.props.children;
  }

  apply() {
    // Title
    const titles = stack.map(s => s.title).filter(t => Boolean(t));
    const title = titles.length > 0 ? titles.slice().reverse()[0] : null;
    if (title) {
      document.title = title;
    }

    // Favicon
    const favicons = stack.map(s => s.favicon).filter(f => Boolean(f));
    const favicon = favicons.length > 0 ? favicons.slice().reverse()[0] : null;
    if (favicon) {
      changeFavicon(favicon);
    }

    // Background color
    const backgroundColors = stack
      .map(s => s.backgroundColor)
      .filter(bC => Boolean(bC));
    const backgroundColor =
      backgroundColors.length > 0
        ? backgroundColors.slice().reverse()[0]
        : null;

    // Background
    const backgrounds = stack.map(s => s.background).filter(b => Boolean(b));
    const background =
      backgrounds.length > 0 ? backgrounds.slice().reverse()[0] : null;

    const classNames = stack.map(s => s.className).filter(c => Boolean(c));

    const css = backgroundColor
      ? { backgroundColor }
      : background
      ? { background }
      : {};
    const className = cx(classNames, emotion(css));
    const classNameList = className.split(" ");
    const $html = document.querySelector("html");
    $html.removeAttribute("class");
    // because of IE, do not use a spread to classList.add()
    classNameList.forEach(cn => {
      $html.classList.add(cn);
    });
  }
}

export { Page };
