import { createBranchError } from "../../components/BranchError";

const {
  SetupError: SetupAppError,
  Error: AppError,
  NotFound,
  FatalError,
  useError: useAppError,
} = createBranchError();

export { SetupAppError, AppError, NotFound, FatalError, useAppError };
