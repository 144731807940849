import React from "react";
import isFunction from "lodash/isFunction";

class Lifecycle extends React.Component {
  async componentDidMount() {
    if (this.props.didMount) {
      await this.props.didMount(this.props);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.didUpdate) {
      await this.props.didUpdate(this.props, prevProps);
    }
  }

  componentWillUnmount() {
    if (this.props.willUnmount) {
      this.props.willUnmount(this.props);
    }
  }

  render() {
    if (isFunction(this.props.children)) {
      return this.props.children();
    }
    if (this.props.children) {
      return this.props.children;
    }
    return null;
  }
}

export { Lifecycle };
