import React from "react";
import { View } from "../../components/View";

class ActivityIndicator extends React.PureComponent {
  render() {
    const { color = "white", size = 48 } = this.props;
    return (
      <svg viewBox="0 0 96 24" style={{ width: size, height: size / 4 }}>
        <g fill={color}>
          <View
            tag="circle"
            cx="12"
            cy="12"
            r="12"
            css={{
              opacity: 0,
              animation: "fade .6s 0s ease infinite",
            }}
          />
          <View
            tag="circle"
            cx="48"
            cy="12"
            r="12"
            css={{
              opacity: 0,
              animation: "fade .6s .2s ease infinite",
            }}
          />
          <View
            tag="circle"
            cx="84"
            cy="12"
            r="12"
            css={{
              opacity: 0,
              animation: "fade .6s .4s ease infinite",
            }}
          />
        </g>
      </svg>
    );
  }
}

export { ActivityIndicator };
