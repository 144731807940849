import React from "react";
import { colors } from "../../../../constants";
import { Text } from "./Text";

class SectionTitleText extends React.Component {
  render() {
    return (
      <Text
        weight="500"
        size={16}
        line={22 / 16}
        color={colors.goldText}
        {...this.props}
      />
    );
  }
}

export { SectionTitleText };
