import React from "react";
import { View } from "../../../../components/View";
import { Text } from "../../../../components/Text";
import { colors } from "../../../../constants";

function PageTitle({ mode = "dark", title, excerpt }) {
  return (
    <View>
      <View>
        <Text
          size={18}
          weight="500"
          line="140%"
          color={mode === "dark" ? colors.white : colors.midGrey}
        >
          {title}
        </Text>
      </View>
      <View css={{ marginTop: 8 }}>
        <Text
          size={15}
          line="180%"
          color={mode === "dark" ? colors.lightMidGrey : colors.lightMidGrey}
        >
          {excerpt}
        </Text>
      </View>
    </View>
  );
}

export { PageTitle };
