import React from "react";
import { cx, css as emotion } from "emotion";
import facepaint from "facepaint";

const mq = facepaint(["@media(min-width: 375px)", "@media(min-width: 768px)"]);

class ViewC extends React.Component {
  render() {
    const {
      tag: Tag = "div",
      forwardedRef,
      className,
      css,
      ...restProps
    } = this.props;
    return (
      <Tag
        ref={forwardedRef}
        className={cx(emotion(mq(css)), className)}
        {...restProps}
      />
    );
  }
}

const View = React.forwardRef((props, ref) => {
  return <ViewC forwardedRef={ref} {...props} />;
});

export { View };
