import { injectGlobal as css } from "emotion";

export function theme() {
  return {
    colorDarkPurple: "#39384e",
    colorMidPurple: "#716f7e",
    colorGold: "#d3bb39",
    colorDarkGray: "#484848",
    colorMidGray: "#787878",
    colorLightGrey: "#bebebe",
    colorLineGray: "#f2f2f2",
    colorError: "#e14502",
    colorSuccess: "#0cce6b",
  };
}

export function inject() {
  css`
    * {
      box-sizing: border-box;
    }

    html,
    body {
      margin: 0;
      padding: 0;
    }

    html {
      background: #f9f9f9;
    }

    html {
      font-size: 100%;
      font-family: "Ubuntu", sans-serif;
      line-height: 1.125;
      color: #484848;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    button,
    input,
    select,
    option,
    textarea {
      font: inherit;
    }

    html.buyer-realm {
      background: #f2f2f2;
      font-family: "Lato", sans-serif;
    }

    html.superadmin-realm {
      font-family: "Lato", sans-serif;
    }

    html.accounts-small,
    html.accounts-small body,
    html.accounts-small #root,
    html.stretch,
    html.stretch body,
    html.stretch #root {
      height: 100%;
    }
    html.stretch #root {
      display: flex;
      flex-direction: column;
    }
    html.stretch #root > * {
      flex: 1 0 auto;
    }

    svg {
      display: block;
    }

    strong {
      font-weight: 700;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;
}
