import React from "react";
import { Hover } from "./index";

class DualHover extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Hover>
        {({ hovered: hovered1, bind: bind1 }) => (
          <Hover>
            {({ hovered: hovered2, bind: bind2 }) =>
              children({
                hovered: hovered1 || hovered2,
                hovers: [hovered1, hovered2],
                binds: [bind1, bind2],
              })
            }
          </Hover>
        )}
      </Hover>
    );
  }
}

export { DualHover };
