import React from "react";

class Menu extends React.Component {
  render() {
    const { width = 18, height = 15, color = "currentColor" } = this.props;
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 1.5C0 0.671573 0.671573 0 1.5 0H16.5C17.3284 0 18 0.671573 18 1.5C18 2.32843 17.3284 3 16.5 3H1.5C0.671573 3 0 2.32843 0 1.5ZM0 7.5C0 6.67157 0.671573 6 1.5 6H11.5C12.3284 6 13 6.67157 13 7.5C13 8.32843 12.3284 9 11.5 9H1.5C0.671573 9 0 8.32843 0 7.5ZM1.5 12C0.671573 12 0 12.6716 0 13.5C0 14.3284 0.671573 15 1.5 15H14.5C15.3284 15 16 14.3284 16 13.5C16 12.6716 15.3284 12 14.5 12H1.5Z"
          fill={color}
        />
      </svg>
    );
  }
}

export { Menu };
