import { useMemo } from "react";
import { getEnv } from "../utilities/env";

function useEnv() {
  const env = useMemo(() => {
    return getEnv();
  }, []);
  return env;
}

export { useEnv };
