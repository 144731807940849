import React from "react";
import { Menu as MenuIcon } from "../../icons/Menu";
import { View } from "../../../../components/View";
import { CloseMD } from "../../icons/Close/Close.MD";

function MenuButton({ opened, ...restProps }) {
  return (
    <View
      tag="button"
      type="button"
      css={{
        appearance: "none",
        padding: 0,
        margin: 0,
        width: 24,
        height: 24,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        background: "none",
        border: 0,
        cursor: "pointer",
        pointerEvents: "auto",
      }}
      {...restProps}
    >
      {opened ? (
        <CloseMD size={18} color="white" />
      ) : (
        <MenuIcon width={18} height={15} color="white" />
      )}
    </View>
  );
}

export { MenuButton };
