import { useCheckForSupportedBrowser } from "../../hooks/useCheckForSupportedBrowser";

function RequireBrowserFeatures({ features, fallback = null, children }) {
  const isSupportedBrowser = useCheckForSupportedBrowser(features);
  if (!isSupportedBrowser) {
    return fallback;
  }
  return children;
}

export { RequireBrowserFeatures };
