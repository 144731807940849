import React from "react";
import { colors } from "../../../../constants";
import { Text } from "./Text";

class PageTitleText extends React.Component {
  render() {
    return (
      <Text
        weight="500"
        size={20}
        line={28 / 20}
        spacing="-0.25px"
        color={colors.darkGrey}
        transform="uppercase"
        {...this.props}
      />
    );
  }
}

export { PageTitleText };
