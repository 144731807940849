import React, { useRef, useEffect } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Transition } from "react-spring-legacy";
import { AnimatedView } from "../../../../components/View/AnimatedView";
import { colors } from "../../../../constants";
import { PageContainer } from "../PageContainer";

const transitionConfig = {
  tension: 210,
  friction: 20,
  precision: 0.1,
  clamp: true,
};

function OverlayContainer({
  headerHeight,
  children,
  color = colors.darkPurple,
  zIndex,
  forceFullHeight = false,
}) {
  const scrollContainer = useRef(null);
  const hasOverlay = Boolean(children);

  useEffect(() => {
    if (!hasOverlay) {
      return;
    }
    const scrollContainerEl = scrollContainer.current;
    disableBodyScroll(scrollContainerEl);
    return () => {
      enableBodyScroll(scrollContainerEl);
    };
  }, [hasOverlay]);

  return (
    <Transition
      native
      items={children}
      from={{ bgOpacity: 0, height: "0%", opacity: 0, overflow: "hidden" }}
      enter={[
        { bgOpacity: 1, height: "100%", opacity: 0, overflow: "hidden" },
        { bgOpacity: 1, height: "100%", opacity: 1, overflow: "auto" },
      ]}
      leave={[
        { bgOpacity: 1, height: "100%", opacity: 0, overflow: "hidden" },
        { bgOpacity: 0, height: "0%", opacity: 0, overflow: "hidden" },
      ]}
      config={transitionConfig}
    >
      {item =>
        item &&
        (props => (
          <AnimatedView
            css={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex,
              minHeight: headerHeight,
              WebkitOverflowScrolling: "touch",
              WebkitBackfaceVisibility: "hidden",
              backgroundColor: color,
            }}
            style={{
              height: props.height,
              overflow: props.overflow,
              opacity: props.bgOpacity,
            }}
            ref={scrollContainer}
          >
            <AnimatedView
              css={{
                paddingTop: headerHeight,
                height: forceFullHeight ? "100%" : "auto",
              }}
              style={{
                opacity: props.opacity,
              }}
            >
              <PageContainer size="narrow" forceFullHeight={forceFullHeight}>
                {item}
              </PageContainer>
            </AnimatedView>
          </AnimatedView>
        ))
      }
    </Transition>
  );
}

export { OverlayContainer };
