import React from "react";
import { rem } from "polished";
import isNumber from "lodash/isNumber";
import isArray from "lodash/isArray";
import { View } from "../View";

const getSize = size => (isNumber(size) ? rem(size) : size);

class Text extends React.Component {
  render() {
    const {
      display = "inline",
      family,
      size = "inherit",
      weight = "inherit",
      align = "inherit",
      line = "inherit",
      color = "inherit",
      transform = "inherit",
      spacing = "normal",
      rendering = "auto",
      fontStyle,
      decoration,
      ellipsis = false,
      cursor,
      transition,
      wordBreak,
      css,
      ...restProps
    } = this.props;
    return (
      <View
        css={{
          display,
          ...(family ? { fontFamily: family } : {}),
          ...(fontStyle ? { fontStyle } : {}),
          fontSize: isArray(size) ? size.map(getSize) : getSize(size),
          fontWeight: weight,
          letterSpacing: spacing,
          lineHeight: line,
          color,
          textAlign: align,
          textTransform: transform,
          textRendering: rendering,
          ...(decoration ? { textDecoration: decoration } : {}),
          ...(ellipsis
            ? {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }
            : {}),
          ...(transition ? { transition } : {}),
          ...(wordBreak ? { wordBreak } : {}),
          ...(cursor ? { cursor } : {}),
        }}
        {...restProps}
      />
    );
  }
}

export { Text };
