import React from "react";
import { margin } from "polished";
import { View } from "../../../../components/View";

const maxWidths = {
  normal: 1140,
  narrow: 640,
};

class PageContainer extends React.Component {
  render() {
    const { size = "normal", forceFullHeight = false } = this.props;
    return (
      <View
        css={{
          maxWidth: maxWidths[size],
          height: forceFullHeight ? "100%" : "auto",
          width: "100%",
          ...margin(null, "auto"),
          paddingLeft: [12, 24, 24],
          paddingRight: [12, 24, 24],
        }}
      >
        {this.props.children}
      </View>
    );
  }
}

export { PageContainer };
