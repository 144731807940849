import React from "react";
import { Text as TextPrimitive } from "../../../../components/Text";

class Text extends React.Component {
  render() {
    return <TextPrimitive {...this.props} />;
  }
}

export { Text };
