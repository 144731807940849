import React from "react";
import { Text } from "./Text";

class ParagraphText extends React.Component {
  render() {
    const { size = "normal" } = this.props;
    const fontSizes = {
      normal: "inherit",
      small: 14,
    };
    return (
      <Text
        display="block"
        line={1.425}
        size={fontSizes[size]}
        {...this.props}
      />
    );
  }
}

export { ParagraphText };
