import React, { useContext, useMemo } from "react";
import { OverlayContainer } from "../AgentBranch/components/OverlayContainer/OverlayContainer";
import { SupportModal } from "../../components/SupportModal/SupportModal";
import { Link } from "../../components/Link";
import { useToggle } from "../../hooks/useToggle";
import { ActAsButton } from "../../components/ActAsButton";
import { rgb } from "polished";

const ContactSupportContext = React.createContext({});

const useContactSupport = () => useContext(ContactSupportContext);
function SetupContactSupport({ children }) {
  const [showContactSupport, toggleContactSupport] = useToggle();
  const context = useMemo(() => {
    return { showContactSupport, toggleContactSupport };
  }, [showContactSupport, toggleContactSupport]);
  return (
    <ContactSupportContext.Provider value={context}>
      {children}
    </ContactSupportContext.Provider>
  );
}

function ContactSupport({ analytics }) {
  const { showContactSupport, toggleContactSupport } = useContactSupport();
  const overlayBody = useMemo(() => {
    return showContactSupport ? (
      <SupportModal
        closeOverlay={() => {
          toggleContactSupport();
        }}
        analytics={analytics}
      />
    ) : null;
  }, [analytics, showContactSupport, toggleContactSupport]);
  return (
    <OverlayContainer headerHeight={0} zIndex={60} color={rgb(52, 51, 68)}>
      {overlayBody}
    </OverlayContainer>
  );
}

function SupportTextLink({ children }) {
  const { toggleContactSupport } = useContactSupport();
  return (
    <ActAsButton
      tag={Link}
      onClick={() => {
        toggleContactSupport();
      }}
    >
      {children}
    </ActAsButton>
  );
}

export {
  SetupContactSupport,
  ContactSupport,
  useContactSupport,
  ContactSupportContext,
  SupportTextLink,
};
