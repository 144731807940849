import MemoryStorage from "memorystorage";

const TEST_KEY = "__test";

function hasStorage(name) {
  try {
    const storage = window[name];
    storage.setItem(TEST_KEY, "1");
    storage.removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
}

export function useStorage(name = useStorage.LOCAL_STORAGE) {
  if (hasStorage(name)) {
    return window[name];
  }
  return MemoryStorage("earnnest");
}

useStorage.LOCAL_STORAGE = "localStorage";
useStorage.SESSION_STORAGE = "sessionStorage";

export const localStorage = useStorage(useStorage.LOCAL_STORAGE);
export const sessionStorage = useStorage(useStorage.SESSION_STORAGE);
