import isString from "lodash/isString";
import isPlainObject from "lodash/isPlainObject";
import { API_PREVIEW_REGEX, API_STAGING_REGEX } from "../constants";
import { getEnv } from "./env";

export function getEnvFromApiOrigin(url) {
  if (API_STAGING_REGEX.test(url)) {
    const [, env] = API_STAGING_REGEX.exec(url);
    return env;
  } else if (API_PREVIEW_REGEX.test(url)) {
    const [, env] = API_PREVIEW_REGEX.exec(url);
    return env;
  } else {
    return url;
  }
}

export function getApiOriginFromEnv(env) {
  let url;
  if (/^(?:stag|release)$/.test(env)) {
    url = "https://en-main-" + env + ".herokuapp.com";
  } else if (/^\d+$/.test(env)) {
    url = "https://en-main-stag-pr-" + env + ".herokuapp.com";
  } else if (/^http/.test(env)) {
    url = env;
  } else {
    const envFromEnv = getEnv();
    url = envFromEnv.REACT_APP_API_ORIGIN;
  }
  return url;
}

export function getEffectiveEnvFromEnv(env) {
  return getEnvFromApiOrigin(getApiOriginFromEnv(env));
}

export function getDefaultEnv() {
  const envFromEnv = getEnv();
  return getEnvFromApiOrigin(envFromEnv.REACT_APP_API_ORIGIN);
}

// Returns a new location with the "env" preserved in its search
export function preserveEnvInLocation(currSearch, targetLocation) {
  if (isString(targetLocation)) {
    const hasQP = targetLocation.indexOf("?") !== -1;
    const toPath = hasQP ? targetLocation.split("?")[0] : targetLocation;
    return {
      pathname: toPath,
      search: generateEnvSearch(
        currSearch,
        hasQP ? targetLocation.split("?")[1] : "",
      ),
    };
  }
  if (isPlainObject(targetLocation)) {
    return {
      ...targetLocation,
      search: generateEnvSearch(currSearch, targetLocation.search || ""),
    };
  }
  throw new Error("to parameter should be a string or an object");
}

function generateEnvSearch(currSearch, toSearch) {
  const toParams = new URLSearchParams(toSearch);
  const currParams = new URLSearchParams(currSearch);
  const currEnvParam = currParams.get("env");
  const toEnvParam = toParams.get("env");
  if (currEnvParam && !toEnvParam) {
    toParams.set("env", currEnvParam);
  }
  return toParams.toString();
}

export function getLogLinkForEnv(env, traceId) {
  const currentEnv =
    !env || env === "show" ? getEnv().REACT_APP_API_ORIGIN : env;
  const prRegex = /^https:\/\/en-main-stag-pr-(\d+).herokuapp.com/.exec(
    currentEnv,
  );
  if (/^\d+$/.test(currentEnv) || prRegex) {
    return null;
  }
  const paperTrailSystem =
    currentEnv === "stag" ||
    currentEnv === "https://api-gateway.earnnest.com/api_staging"
      ? "-en-main-stag"
      : currentEnv === "https://api-gateway.earnnest.com/api_demo"
      ? "-en-main-demo"
      : currentEnv === "https://api-gateway.earnnest.com/api_release"
      ? "-en-main-release"
      : currentEnv === "https://api-gateway.earnnest.com/api" ||
        currentEnv === "https://connect.earnnest.com"
      ? "-earnnest-services-private"
      : null;

  return (
    paperTrailSystem &&
    "https://my.papertrailapp.com/systems/earnnest" +
      paperTrailSystem +
      "/events?q=trace%3D" +
      traceId
  );
}
