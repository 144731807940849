import { useState, useCallback } from "react";

function useToggle(initialValue = false) {
  const [value, setValue] = useState(initialValue);
  const toggleValue = useCallback(force => {
    setValue(prev => (force == null ? !prev : Boolean(force)));
  }, []);
  return [value, toggleValue];
}

export { useToggle };
