import React from "react";

class CloseMD extends React.Component {
  render() {
    const { size = 18, color = "currentColor" } = this.props;
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58578 12.7574C0.804735 13.5384 0.804735 14.8047 1.58578 15.5858C2.36683 16.3668 3.63316 16.3668 4.41421 15.5858L9 11L13.5858 15.5858C14.3668 16.3668 15.6332 16.3668 16.4142 15.5858C17.1953 14.8047 17.1953 13.5384 16.4142 12.7574L11.8284 8.17157L16.5858 3.41421C17.3668 2.63316 17.3668 1.36684 16.5858 0.585787C15.8047 -0.195262 14.5384 -0.195263 13.7574 0.585787L9 5.34314L4.24264 0.585787C3.4616 -0.195262 2.19527 -0.195262 1.41422 0.585787C0.633168 1.36684 0.633168 2.63317 1.41422 3.41421L6.17157 8.17157L1.58578 12.7574Z"
          fill={color}
        />
      </svg>
    );
  }
}

export { CloseMD };
