import isEqualWith from "lodash/isEqualWith";

const shallowEqual = (value, other) =>
  isEqualWith(value, other, (v, o) => {
    for (let key1 in v) {
      if (!(key1 in o) || v[key1] !== o[key1]) {
        return false;
      }
    }
    for (let key2 in o) {
      if (!(key2 in v) || v[key2] !== o[key2]) {
        return false;
      }
    }
    return true;
  });

export { shallowEqual };
