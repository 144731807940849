import React, { useEffect, useMemo } from "react";

// Future TS things
// declare global {
//   interface Window {
//     analytics: any; // Segment
//     Sentry: any; // Sentry
//     console: any;
//   }
// }

const AnalyticsJsMethods = [
  "trackSubmit",
  "trackClick",
  "trackLink",
  "trackForm",
  "pageview",
  "identify",
  "reset",
  "group",
  "track",
  "ready",
  "alias",
  "debug",
  "page",
  "once",
  "off",
  "on",
  "addSourceMiddleware",
  "addIntegrationMiddleware",
  "setAnonymousId",
  "addDestinationMiddleware",
];

// TODO: Determine actual definitions, possibly use TS in future
// export type DefaultTrackingFn = (...args: any) => any;
// export type TrackingApi = {
//   track: (event: string, properties: Object) => void;
//   captureException: (exception: Error) => void;
//   captureMessage: (message: string) => void;
//   [k: string]: DefaultTrackingFn;
// };

// For now, this can be easiy derived. If state is required in the future,
// we can move it to context.
//
// We also memo-ize so as to not have any expensive operations
export const useTracking = () => {
  const api = useMemo(() => {
    return makeExtendedTrackingApi();
  }, []);
  return api;
};

// This function is pulled from the portal / library, and we couldn't utilize the
// original function because of issues with React versions
export function SetupTracking({ children, location }) {
  // This is mocking a hook call that manages .env in the library, utilized by
  // the integration portal
  const config = {
    segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  };

  const analytics = useMemo(() => {
    console.log("[Tracking] Setting up tracking...");

    var analytics = (window.analytics = window.analytics || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      return;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error("[Tracking] Segment snippet included twice.");
      }
      return;
    }

    // If there is no segment write key provided, return.
    if (!config.segmentWriteKey) {
      console.log(
        "[Tracking] Skipping tracking setup, no write key provided...",
      );
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = AnalyticsJsMethods;

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function(method) {
      return function() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function(key, options) {
      // Create an async script element based on your key.
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";

      // Insert our script next to the first script element.
      var first = document.getElementsByTagName("script")[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    analytics.SNIPPET_VERSION = "4.1.0";
    analytics.load(config.segmentWriteKey);
    console.log("[Tracking] Successfully setup tracking.");

    return analytics;
  }, [config.segmentWriteKey]);

  useEffect(() => {
    analytics?.page(location);
  }, [location, analytics]);

  return <>{children}</>;
}

function makeExtendedTrackingApi() {
  const buildGenericApiMethod = (namespace, method) => (...args) => {
    return namespace[method](...args);
  };

  const defaultAnalyticsJsApi = AnalyticsJsMethods.reduce((apiObj, method) => {
    return {
      ...apiObj,
      [method]: buildGenericApiMethod(window.analytics, method),
    };
  }, {});

  // Extend the default analytics js API with methods of our own.
  // Mostly for error reporting.
  const extendedTrackingApi = {
    ...defaultAnalyticsJsApi,
    // captureException: buildGenericApiMethod(window.Sentry, "captureExpection"),
  };

  return extendedTrackingApi;
}
