import differenceInMinutes from "date-fns/difference_in_minutes";
import { useStorage as provideStorage } from "./storage";

export async function check() {
  const storage = provideStorage(provideStorage.LOCAL_STORAGE);
  const sessionActivityAt = storage.getItem("customerSessionActivityAt");
  return differenceInMinutes(new Date(), new Date(sessionActivityAt)) < 30;
}

export async function authenticate(tokens) {
  const storage = provideStorage(provideStorage.LOCAL_STORAGE);
  storage.setItem("customerAccessToken", tokens.accessToken);
  storage.setItem("customerRefreshToken", tokens.refreshToken);
  storage.setItem("customerSessionActivityAt", new Date());
  return true;
}

export async function roll() {
  const storage = provideStorage(provideStorage.LOCAL_STORAGE);
  storage.setItem("customerSessionActivityAt", new Date());
  return true;
}
