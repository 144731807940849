const featureTests = {
  "for...of": () => {
    try {
      // eslint-disable-next-line
      eval("for (var i of []);");
      return true;
    } catch (ex) {
      return false;
    }
  },
};

const checkForBrowserFeatures = features => {
  for (let i = 0; i < features.length; ++i) {
    const currentFeature = features[i];
    const featureTest = featureTests[currentFeature];
    const isUnsupported = !featureTest();
    if (isUnsupported) {
      return false;
    }
  }
  return true;
};

export { checkForBrowserFeatures };
