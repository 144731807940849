import React from "react";
import { View } from "../../../../components/View";
import { SectionTitle } from "../Text/SectionTitle";
import { TooltipPopper } from "../../../OfficeBranch/components/Tooltip/TooltipPopper";
import { InfoIcon } from "../../icons/InfoIcon/InfoIcon";
import { margin } from "polished";

function OverlayFieldset({
  mode = "dark",
  legend,
  description,
  tooltip,
  children,
}) {
  return (
    <View>
      <View
        css={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <SectionTitle mode={mode} title={legend} excerpt={description} />
        {tooltip && (
          <View css={{ display: "inline-flex", ...margin(-7, -8, -7, 24 - 8) }}>
            <TooltipPopper text={tooltip} mode={mode}>
              <InfoIcon />
            </TooltipPopper>
          </View>
        )}
      </View>
      <View css={{ marginTop: 8 }}>{children}</View>
    </View>
  );
}

export { OverlayFieldset };
