import React from "react";
import { Page } from "../../components/Page";
import { MediumButton } from "../../kudzu/MediumButton";
import { StatusCodeScreenTemplate } from "../AppBranch/StatusCodeScreenTemplate";
import { SupportTextLink } from "../AppBranch/ContactSupport";

function AppClientErrorScreen() {
  return (
    <Page
      title="Error — Earnnest"
      backgroundColor="#f9f9f9"
      className="ubuntu stretch"
    >
      <StatusCodeScreenTemplate
        title="Well, that’s awkward."
        subtitle="Oops!"
        description={
          <React.Fragment>
            We encountered an error and could not complete your request.
            <br />
            Try refreshing the page. If the issue persists,{" "}
            <SupportTextLink>contact support</SupportTextLink>.
          </React.Fragment>
        }
        home={
          <MediumButton
            minWidth={160}
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </MediumButton>
        }
      />
    </Page>
  );
}

export default AppClientErrorScreen;
export { AppClientErrorScreen };
