import React from "react";
import { Page } from "../../components/Page";
import { StatusCodeScreenTemplate } from "../AppBranch/StatusCodeScreenTemplate";

function AppFatalErrorScreen() {
  return (
    <Page
      title="Server Error — Earnnest"
      backgroundColor="#f9f9f9"
      className="ubuntu stretch"
    >
      <StatusCodeScreenTemplate
        title="Well, that’s awkward."
        subtitle="Error 500"
        description={
          <React.Fragment>
            The server encountered an internal error and could not complete your
            request.
          </React.Fragment>
        }
        home={false}
      />
    </Page>
  );
}

export default AppFatalErrorScreen;
export { AppFatalErrorScreen };
