import React, { useMemo } from "react";
import { View } from "./View";
import { KeyCodes } from "../constants";

const DEFAULT_ON_CLICK = () => {};
const DEFAULT_ON_KEY_DOWN = () => {};
const DEFAULT_ON_KEY_UP = () => {};

function ActAsButton({
  onClick = DEFAULT_ON_CLICK,
  onKeyDown = DEFAULT_ON_KEY_DOWN,
  onKeyUp = DEFAULT_ON_KEY_UP,
  disabled = false,
  ...props
}) {
  const onDisablableClick = useMemo(() => {
    function onDisablableClick() {
      if (disabled) {
        return;
      }
      onClick();
    }
    return onDisablableClick;
  }, [disabled, onClick]);
  return (
    <View
      tag="span"
      disabled={disabled}
      role={disabled ? undefined : "button"}
      tabIndex={disabled ? undefined : "0"}
      onClick={onDisablableClick}
      onKeyDown={e => {
        if (e.keyCode === KeyCodes.SPACE) {
          e.preventDefault();
        } else if (e.keyCode === KeyCodes.ENTER) {
          e.preventDefault();
          onDisablableClick();
        }
        onKeyDown(e);
      }}
      onKeyUp={e => {
        if (e.keyCode === KeyCodes.SPACE) {
          e.preventDefault();
          onDisablableClick();
        }
        onKeyUp(e);
      }}
      {...props}
    />
  );
}

export { ActAsButton };
