export const colors = {
  darkPurple: "#39384e",
  midPurple: "#716f7e",
  gold: "#d3bb39",
  goldText: "#c1a617",
  darkGrey: "#484848",
  midGrey: "#787878",
  lightMidGrey: "#ababab",
  lightGrey: "#bebebe",
  lineGrey: "#eee",
  blue: "#6ea1cf",
  agentBlue: "#41a0f9",
  green: "#0cce6b",
  neueGreen: "#30c97f",
  error: "#ed5828",
  white: "#ffffff",
  transparent: "transparent",
  backgroundLight: "#f9f9f9",
  mammonViewBackground: "#302f41",
  mammonInputUILightDisabled: "#d9d9d9",
  mammonInputBackgroundUILightDisabled: "#eee",
  mammonChip: "#d9d9d9",
  mammonNotificationA: "#dbb83d",
  mammonGrey4: "#e0e0e0",
  mammonViewBackgroundUIDark: "#29283A",
  mammonInputBorderUIDark: "#BAB7CE",
  mammonButtonUIDarkDisabled: "#4F4C61",
  aqua: "#24A494",
  darkOrange: "#C94B22",
  lightOrange: "#EB7B57",
  orange: "#ED5828",
};

export const KeyCodes = {
  ENTER: 13,
  SPACE: 32,
  DOWN: 40,
};

export const COLORS = colors;

export const MAX_IAV_TOKEN_TRY_COUNT = 5;

export const STORAGE_PLAID_LINK_TOKEN_KEY = "earnnest-plaid-link-token";
export const STORAGE_PLAID_REDIRECT_TO_KEY = "earnnest-plaid-redirect-to";
export const STORAGE_PLAID_TRX_TOKEN_KEY = "earnnest-plaid-trx-token";

export const FALLBACK_TERMS = {
  personal: "personal check",
  cashier: "cashier's check",
  wire: "wire transfer",
};

export const FALLBACK_TRANSFER_TYPES = {
  personal: "check",
  cashier: "check",
  wire: "wire",
};

export const US_ZIP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

// prettier-ignore
export const US_ZIP_4_MASK = [
  /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/
];

// prettier-ignore
export const FULL_SSN_MASK = [
  /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];
export const LAST4_SSN_MASK = [/\d/, /\d/, /\d/, /\d/];
export const STATE_ABBREVIATION_MASK = [/[A-Z]/i, /[A-Z]/i];
// prettier-ignore
export const US_PHONE_MASK = [
  /[1-9]/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];
// prettier-ignore
export const US_PHONE_WITH_COUNTRY_CODE_MASK = [
  "1", "-",
  /[1-9]/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, "-",
  /\d/, /\d/, /\d/, /\d/
];
// prettier-ignore
export const EIN_MASK = [
  /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
];
export const PIN_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
// prettier-ignore
export const ROUTING_NUMBER_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
// prettier-ignore
export const ACCOUNT_NUMBER_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export const STATE_NAMES = [
  { abbreviation: "AL", name: "Alabama" },
  { abbreviation: "AK", name: "Alaska" },
  { abbreviation: "AZ", name: "Arizona" },
  { abbreviation: "AR", name: "Arkansas" },
  { abbreviation: "CA", name: "California" },
  { abbreviation: "CO", name: "Colorado" },
  { abbreviation: "CT", name: "Connecticut" },
  { abbreviation: "DE", name: "Delaware" },
  { abbreviation: "DC", name: "District of Columbia" },
  { abbreviation: "FL", name: "Florida" },
  { abbreviation: "GA", name: "Georgia" },
  { abbreviation: "HI", name: "Hawaii" },
  { abbreviation: "ID", name: "Idaho" },
  { abbreviation: "IL", name: "Illinois" },
  { abbreviation: "IN", name: "Indiana" },
  { abbreviation: "IA", name: "Iowa" },
  { abbreviation: "KS", name: "Kansas" },
  { abbreviation: "KY", name: "Kentucky" },
  { abbreviation: "LA", name: "Louisiana" },
  { abbreviation: "ME", name: "Maine" },
  { abbreviation: "MD", name: "Maryland" },
  { abbreviation: "MA", name: "Massachusetts" },
  { abbreviation: "MI", name: "Michigan" },
  { abbreviation: "MN", name: "Minnesota" },
  { abbreviation: "MS", name: "Mississippi" },
  { abbreviation: "MO", name: "Missouri" },
  { abbreviation: "MT", name: "Montana" },
  { abbreviation: "NE", name: "Nebraska" },
  { abbreviation: "NV", name: "Nevada" },
  { abbreviation: "NH", name: "New Hampshire" },
  { abbreviation: "NJ", name: "New Jersey" },
  { abbreviation: "NM", name: "New Mexico" },
  { abbreviation: "NY", name: "New York" },
  { abbreviation: "NC", name: "North Carolina" },
  { abbreviation: "ND", name: "North Dakota" },
  { abbreviation: "OH", name: "Ohio" },
  { abbreviation: "OK", name: "Oklahoma" },
  { abbreviation: "OR", name: "Oregon" },
  { abbreviation: "PA", name: "Pennsylvania" },
  { abbreviation: "RI", name: "Rhode Island" },
  { abbreviation: "SC", name: "South Carolina" },
  { abbreviation: "SD", name: "South Dakota" },
  { abbreviation: "TN", name: "Tennessee" },
  { abbreviation: "TX", name: "Texas" },
  { abbreviation: "UT", name: "Utah" },
  { abbreviation: "VT", name: "Vermont" },
  { abbreviation: "VA", name: "Virginia" },
  { abbreviation: "WA", name: "Washington" },
  { abbreviation: "WV", name: "West Virginia" },
  { abbreviation: "WI", name: "Wisconsin" },
  { abbreviation: "WY", name: "Wyoming" },
  { abbreviation: "VI", name: "US Virgin Islands" },
  { abbreviation: "PR", name: "Puerto Rico" },
  { abbreviation: "GU", name: "Guam" },
];

export const STATE_ABBREVIATIONS = STATE_NAMES.map(state => state.abbreviation);

export const API_PREVIEW_REGEX = /^https:\/\/en-main-stag-pr-(\d+)\.herokuapp\.com$/;
export const API_STAGING_REGEX = /^https:\/\/en-main-(stag|release)\.herokuapp\.com$/;

export const DIMENSIONS = {
  CustomerEmail: "dimension1",
  TransactionId: "dimension2",
  AgentEmail: "dimension3",
};

export const METRICS = {
  TransactionDollars: "metric1",
};

// Events used for user tracking
// prettier-ignore
export const EVENTS = {
  BuyerTransactionHub: {
    ClickRecordsCorrect: { category: "Buyer Authorization", action: "Earnest Money Reviewed" },
    ClickConfirmIdentity: { category: "Buyer Authorization", action: "Confirm Identity Clicked" },
    ClickSubmitRegistration: { category: "Buyer Authorization", action: "Registration Submission Clicked" },
    ClickSelectIdType: { category: "Buyer Authorization", action: "Select ID Type Clicked" },
    ClickAttachImage: { category: "Buyer Authorization", action: "Attach Image Clicked" },
    ClickDeleteImage: { category: "Buyer Authorization", action: "Delete Image Clicked" },
    ClickUploadId: { category: "Buyer Authorization", action: "Upload ID Clicked" },
    ClickResubmitDocument: { category: "Buyer Authorization", action: "Document Resubmit Clicked" },
    ClickSelectFunding: { category: "Buyer Authorization", action: "Select Funding Clicked" },
    ClickAddFundingBank: { category: "Buyer Authorization", action: "Link Checking Account Clicked" },
    ClickVerifyDeposits: { category: "Buyer Authorization", action: "Verify Deposits Clicked" },
    ClickPayEarnestMoney: { category: "Buyer Authorization", action: "Pay Earnest Money Clicked" },
    ClickReviewAndPay: { category: "BuyerAuthorization", action: "Review and Pay Clicked"},
    DismissReviewAndPay: { category: "BuyerAuthorization", action: "Review and Pay Dismissed"},
    ClickBack: { category: "Buyer Authorization", action: "Back Clicked" },
    OpenLearnMore: { category: "Buyer Authorization", action: "Learn More Opened" },
  },
  Global: {
    ContactSupport: { category: "Global", action: "Contact Support Clicked"}
  }
};

export const EarnnestApiResources = {
  USER: ["user", "users"],
  OFFICE: ["market-center", "market-centers"],
  TRANSACTION: ["transaction", "transactions"],
  TRANSACTION_INVITATION: ["transaction_invitation", "transaction_invitations"],
  ORGANIZATION: ["organization", "organizations"],
  ESCROW_ACCOUNT: ["escrow-account", "escrow-accounts"],
  FUNDING_SOURCE: ["funding-source", "funding-sources"],
  INTEGRATION: ["integration", "integrations"],
};

export const Processors = {
  PLAID: "plaid",
  DWOLLA: "dwolla",
};

export const BANK_BLACKLIST = [
  { name: "NFCU", code: "ins_15", bannedSubtypes: ["savings"] },
];

export const FRIENDLY_TRX_STATUS = {
  pending: "Pending",
  unsubmitted: "Created",
  submitted: "Requested",
  authorized: "Authorized",
  successful: "Successful",
  failed: "Failed",
  offline: "Offline",
};

export const FRIENDLY_TRANSFER_STATUS = {
  initiated: "Initiated",
  "pending:funds": "Initiated",
  "pending:deposit": "Cleared",
  complete: "Deposited",
};

export const FRIENDLY_BUYER_STEP = {
  "0": "",
  "1": "On Identity Verification",
  "2": "On Funding Source Verification",
  "3": "On Authorization",
};

export const FETCH_STATES = {
  Initial: "initial",
  Pending: "pending",
  Ready: "ready",
  Error: "error",
};

export const AGENT_ROLES = {
  Buyer: "buyer",
  Listing: "listing",
};

export const ROLES = {
  Agent: "agent",
  Business: "business",
};

export const FRIENDLY_BUYER_FUNDING = {
  null: "Not Linked",
  linked: "Linked",
};

export const PLAID_SCRIPT_URL =
  "https://cdn.plaid.com/link/v2/stable/link-initialize.js";

export const DWOLLA_SCRIPT_URL = "https://cdn.dwolla.com/1/dwolla.js";

export const IAV_ERRORS_TO_NOTIFY_SUPPORT = [
  /user_setup_required/i,
  /item_locked/i,
  /item_login_required/i,
  /item_not_supported/i,
  /item_no_verification/i,
  /incorrect_deposit_amounts/i,
  /too_many_verification_attempts/i,
  /user_setup_required/i,
  /mfa_not_supported/i,
  /no_auth_accounts/i,
  /institution_down/i,
  /institution_not_responding/i,
  /institution_not_available/i,
  /institution_no_longer_supported/i,
  /internal_server_error/i,
  // This is an error we've encountered while not officially documented
  /no-depository-accounts/i,
];

// Mark the beginning of time for earnnest. December 1, 2017. Used as a start
// time for "all time" date queries. Note the monthIndex as 11 for Dec.
export const EARNNEST_BEGINNING_OF_TIME = new Date(2017, 11, 1);
