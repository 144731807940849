import React, { useContext, useEffect } from "react";
import { withRouter } from "../../components/Router";

const createBranchError = () => {
  const ErrorContext = React.createContext({});

  const useError = () => useContext(ErrorContext);

  class SetupError extends React.Component {
    constructor(...args) {
      super(...args);

      this.state = {
        error: null,
        errorLocation: null,
        notFound: this.notFound.bind(this),
        fatalError: this.fatalError.bind(this),
      };
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.state.errorLocation &&
        this.props.location.pathname !== this.state.errorLocation.pathname
      ) {
        this.setState({ error: null, errorLocation: null });
      }
    }

    render() {
      return <ErrorContext.Provider value={this.state} {...this.props} />;
    }

    notFound() {
      this.setState({ error: "notFound", errorLocation: this.props.location });
    }

    fatalError() {
      this.setState({
        error: "fatalError",
        errorLocation: this.props.location,
      });
    }
  }
  const SetupErrorWithRouter = withRouter(SetupError);

  class Error extends React.Component {
    render() {
      return <ErrorContext.Consumer {...this.props} />;
    }
  }

  function NotFound() {
    const { notFound } = useError();
    useEffect(() => {
      notFound();
    }, [notFound]);
    return null;
  }

  function FatalError() {
    const { fatalError } = useError();
    useEffect(() => {
      fatalError();
    }, [fatalError]);
    return null;
  }

  return {
    SetupError: SetupErrorWithRouter,
    Error,
    NotFound,
    FatalError,
    useError,
  };
};

export { createBranchError };
