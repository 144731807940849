import React from "react";

class CodeSplitErrorBoundary extends React.Component {
  state = {
    codeSplitError: false,
  };

  componentDidCatch(error) {
    if (
      error.name === "ChunkLoadError" ||
      (error.request && error.type === "missing")
    ) {
      console.log(
        "[Code Split Error Boundary] Unable to find code split chunk. Rendering a fatal error.",
      );
      this.setState({ codeSplitError: true });
    } else {
      console.log(
        "[Code Split Error Boundary] Skipping since it's not a code split chunk error.",
      );
      throw error;
    }
  }

  render() {
    const { codeSplitError } = this.state;
    const { children, fallback = null } = this.props;

    if (codeSplitError) {
      return fallback;
    }

    return children;
  }
}

export { CodeSplitErrorBoundary };
