import React from "react";
import {
  Route as ReactRouterRoute,
  Redirect as ReactRouterRedirect,
  Link as ReactRouterLink,
  withRouter as withReactRouter,
} from "react-router-dom";
import { preserveEnvInLocation } from "../../utilities/apiBannerHelpers";

function transformRoutePropsToIncludeSearchParams(originalRouteProps) {
  return {
    ...originalRouteProps,
    location: {
      ...originalRouteProps.location,
      searchParams: Array.from(
        new URLSearchParams(originalRouteProps.location.search).entries(),
      ),
    },
  };
}

function transformRoutePropsToPreserveEnvInHistory(originalRouteProps) {
  const origPush = originalRouteProps.history.pushWithoutPreservingEnv
    ? originalRouteProps.history.pushWithoutPreservingEnv
    : originalRouteProps.history.push;
  const origReplace = originalRouteProps.history.replaceWithoutPreservingEnv
    ? originalRouteProps.history.replaceWithoutPreservingEnv
    : originalRouteProps.history.replace;

  originalRouteProps.history.pushWithoutPreservingEnv = origPush;
  originalRouteProps.history.replaceWithoutPreservingEnv = origReplace;

  originalRouteProps.history.push = to => {
    return origPush(
      preserveEnvInLocation(originalRouteProps.location.search, to),
    );
  };
  originalRouteProps.history.replace = to => {
    return origReplace(
      preserveEnvInLocation(originalRouteProps.location.search, to),
    );
  };

  return originalRouteProps;
}

function transformRouteProps(originalRouteProps) {
  return transformRoutePropsToPreserveEnvInHistory(
    transformRoutePropsToIncludeSearchParams(originalRouteProps),
  );
}

class Route extends React.Component {
  render() {
    const { component: Component, render, ...restProps } = this.props;
    // If a Route is not matched, it won't have a location. This is very
    // possible with React Router
    return (
      <ReactRouterRoute
        {...restProps}
        render={route => {
          const props = transformRouteProps(route);
          if (Component) {
            return <Component {...props} />;
          }
          return render(props);
        }}
      />
    );
  }
}

class Redirect extends React.Component {
  render() {
    return (
      <ReactRouterRedirect
        {...this.props}
        to={preserveEnvInLocation(this.props.location.search, this.props.to)}
      />
    );
  }
}

class Link extends React.Component {
  render() {
    const {
      to,
      history,
      location,
      match,
      staticContext,
      ...restProps
    } = this.props;
    return (
      <ReactRouterLink
        {...restProps}
        to={preserveEnvInLocation(location.search, to)}
      />
    );
  }
}

const withModifiedRouter = Component => {
  function WithModifiedRouter(routeProps) {
    return <Component {...transformRouteProps(routeProps)} />;
  }
  return WithModifiedRouter;
};

function withRouter(Component) {
  return withReactRouter(withModifiedRouter(Component));
}

const RedirectWithRouter = withReactRouter(Redirect);
const LinkWithRouter = withReactRouter(Link);
export * from "react-router-dom";
export {
  Route,
  RedirectWithRouter as Redirect,
  LinkWithRouter as Link,
  withRouter,
};
