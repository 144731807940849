import React from "react";
import * as analytics from "../../utilities/analytics";
import * as session from "../../utilities/session";
import { Route } from "../../components/Router";
import { Storage } from "../../components/Storage";
import { ErrorReporter } from "../../components/ErrorReporter";

class AppRoute extends React.Component {
  render() {
    const { component: Component, render, ...restProps } = this.props;
    return (
      <Route
        {...restProps}
        render={route => (
          <Storage>
            {storage => (
              <ErrorReporter>
                {errorReporter => {
                  const props = {
                    storage,
                    session,
                    errorReporter,
                    analytics,
                    ...route,
                  };
                  if (Component) {
                    return <Component {...props} />;
                  }
                  return render(props);
                }}
              </ErrorReporter>
            )}
          </Storage>
        )}
      />
    );
  }
}

export { AppRoute };
