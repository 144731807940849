import React from "react";
import { View } from "../View";

class Link extends React.Component {
  render() {
    return <View tag="a" {...this.props} css={[this.styles().button]} />;
  }

  styles() {
    const {
      display = "inline",
      disabled,
      textDecoration = "underline",
      color = "inherit",
    } = this.props;
    return {
      button: {
        display,
        border: 0,
        padding: 0,
        width: display === "inline" ? "auto" : "100%",
        fontSize: "inherit",
        color,
        lineHeight: "inherit",
        background: "transparent",
        textDecoration: textDecoration,
        opacity: disabled ? 0.5 : 1.0,
        cursor: "pointer",
        transition: "opacity .15s ease-out",
      },
    };
  }
}

export { Link };
