import React from "react";
import { StatusCodeScreenTemplate } from "../AppBranch/StatusCodeScreenTemplate";
import { Page } from "../../components/Page";

function AppNotFoundScreen() {
  return (
    <Page
      title="Not Found — Earnnest"
      backgroundColor="#f9f9f9"
      className="ubuntu stretch"
    >
      <StatusCodeScreenTemplate
        title="Well, that’s awkward."
        subtitle="Error 404"
        description={
          <React.Fragment>
            We tried to find that page but came up empty-handed.
            <br />
            Let's get you back to safety.
          </React.Fragment>
        }
      />
    </Page>
  );
}

export default AppNotFoundScreen;
export { AppNotFoundScreen };
