import React from "react";
import { Text } from "./Text";

class HeadingText extends React.Component {
  render() {
    return (
      <Text size={[16, 16, 18]} weight={700}>
        {this.props.children}
      </Text>
    );
  }
}

export { HeadingText };
