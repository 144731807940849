import React, { useCallback, useEffect } from "react";
import { SetupDataBroadcast } from "../../components/DataBroadcast";
import {
  ErrorBoundary,
  SetupErrorReporter,
} from "../../components/ErrorReporter";
import { withRouter } from "../../components/Router";
import { SetupStorage } from "../../components/Storage";
import { SetupTracking } from "../../components/Tracking";
import { View } from "../../components/View";
import * as analytics from "../../utilities/analytics";
import { AppClientErrorScreen } from "../AppClientErrorScreen";
import { AppFatalErrorScreen } from "../AppFatalErrorScreen";
import { AppNotFoundScreen } from "../AppNotFoundScreen";
import { AppError, SetupAppError } from "./AppError";
import { CodeSplitErrorBoundary } from "./CodeSplitErrorBoundary";
import { SetupColorScheme } from "./ColorScheme";
import { ContactSupport, SetupContactSupport } from "./ContactSupport";

function AppBranch({ location, children }) {
  const sendPageview = useCallback(() => {
    analytics.pageview({
      location: document.location.href,
      page: document.location.pathname,
      title: document.title,
    });
  }, []);

  useEffect(() => {
    analytics.initialize();
  }, []);

  // TODO: REMOVE GA from this and prefer sentry
  useEffect(() => {
    // Whenever the path changes, send a new pageview.
    //
    // TODO: In the future, it's possible that the page information won't
    // be ready when the pathname changes. For instance, we may need to fetch
    // data to populate the title. In that case, we will need a method that's
    // passed down to children (via context probably) that we can call to let
    // our App know that we are ready to send a pageview.
    sendPageview();
  }, [location.pathname, sendPageview]);

  return (
    <SetupErrorReporter>
      <SetupColorScheme location={location}>
        <SetupTracking location={location}>
          <SetupContactSupport>
            <SetupStorage>
              <SetupDataBroadcast>
                <SetupAppError>
                  {/* Suspense needed for code splitting. Should add a fallback */}
                  <React.Suspense fallback={null}>
                    <ErrorBoundary fallback={<AppClientErrorScreen />}>
                      <CodeSplitErrorBoundary
                        fallback={<AppClientErrorScreen />}
                      >
                        <AppError>
                          {appError => {
                            return (
                              <React.Fragment>
                                {appError.error === "notFound" ? (
                                  <AppNotFoundScreen />
                                ) : appError.error === "fatalError" ? (
                                  <AppFatalErrorScreen />
                                ) : (
                                  children
                                )}
                              </React.Fragment>
                            );
                          }}
                        </AppError>
                      </CodeSplitErrorBoundary>
                    </ErrorBoundary>
                  </React.Suspense>
                  {/* Contact support needs to be available to even our errors */}
                  <ContactSupport analytics={analytics} />
                  <View
                    id="tooltip"
                    css={{ position: "fixed", zIndex: 100 }}
                  ></View>
                </SetupAppError>
              </SetupDataBroadcast>
            </SetupStorage>
          </SetupContactSupport>
        </SetupTracking>
      </SetupColorScheme>
    </SetupErrorReporter>
  );
}

const AppBranchWithRouter = withRouter(AppBranch);

export { AppBranchWithRouter as AppBranch };
