import { useMemo } from "react";

export function useSearchParams(search, params) {
  const values = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return [params].flat().map(param => {
      return searchParams.get(param);
    });
  }, [search, params]);
  return values;
}
