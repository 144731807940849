import React, { useContext, useMemo, useState } from "react";
import { useSearchParams } from "../../hooks/useSearchParams";

const ColorSchemeContext = React.createContext({});

const ColorSchemes = {
  DARK: "dark",
  LIGHT: "light",
};

const SchemeMap = {
  dark: ColorSchemes.DARK,
  light: ColorSchemes.LIGHT,
};

const useColorScheme = () => useContext(ColorSchemeContext);
function SetupColorScheme({ children, location }) {
  const [earnnestTheme] = useSearchParams(location.search, "earnnest_theme");
  const schemeFromParam = SchemeMap[earnnestTheme] || ColorSchemes.LIGHT;
  const [colorScheme, setColorScheme] = useState(schemeFromParam);
  const context = useMemo(() => {
    return { colorScheme, setColorScheme };
  }, [colorScheme, setColorScheme]);
  return (
    <ColorSchemeContext.Provider value={context}>
      {children}
    </ColorSchemeContext.Provider>
  );
}

export { useColorScheme, ColorSchemeContext, SetupColorScheme, ColorSchemes };
