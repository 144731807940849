import * as Sentry from "@sentry/browser";
import React, { useContext, useMemo, useState } from "react";
import { useEnv } from "../../hooks/useEnv";

const ErrorReporterContext = React.createContext(null);
const ErrorReporter = ErrorReporterContext.Consumer;
const useErrorReporter = () => useContext(ErrorReporterContext);

const formatComponentStack = str => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = "";
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) ret += `${ret.length ? "\n" : ""}${lines[line]}`;
  }
  return ret;
};

class ErrorBoundary extends React.Component {
  static contextType = ErrorReporterContext;

  constructor(props) {
    super(props);
    this.state = { error: null, info: null };
  }

  componentDidCatch(error, info) {
    const { sentryClient } = this.context;
    if (info && info.componentStack) {
      info.componentStack = formatComponentStack(info.componentStack);
    }
    sentryClient.withScope(scope => {
      scope.setExtras(info);
      sentryClient.captureException(error);
    });
    console.log("[Error Reporter] Notified error reporters of exception.");
    console.log(error.message);
    this.setState({ error, info });
  }

  render() {
    const { error } = this.state;
    const { children, fallback = null } = this.props;

    if (error) {
      return fallback;
    }

    return children;
  }
}

function SetupErrorReporter({ children }) {
  const env = useEnv();
  const [sentryClient] = useState(() => {
    Sentry.init({ dsn: env.REACT_APP_SENTRY_DSN });
    Sentry.setTag("release_stage", env.REACT_APP_ERROR_REPORTING_RELEASE_STAGE);
    return Sentry;
  });

  const errorReporter = useMemo(() => {
    return {
      sentryClient,
      notify(error) {
        sentryClient.captureException(error);
      },
      metadata(fn) {
        Sentry.setExtras(fn({}));
      },
      setUser(user) {
        const { email } = user;
        Sentry.setUser({ email });
        console.log("[Error Reporter] Set user [%s].", email);
      },
    };
  }, [sentryClient]);

  return (
    <ErrorReporterContext.Provider value={errorReporter}>
      {children}
    </ErrorReporterContext.Provider>
  );
}

export {
  SetupErrorReporter,
  ErrorReporter,
  ErrorReporterContext,
  useErrorReporter,
  ErrorBoundary,
};
