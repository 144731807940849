import React from "react";
import { View } from "../../../../components/View";
import { Text } from "../../../../components/Text";
import { colors } from "../../../../constants";

function SectionTitle({ mode = "dark", title, excerpt }) {
  return (
    <View>
      {title && (
        <View>
          <Text
            size={15}
            weight="500"
            line={17 / 15}
            color={mode === "dark" ? colors.mammonGrey4 : colors.midGrey}
          >
            {title}
          </Text>
        </View>
      )}
      {excerpt && (
        <View css={{ marginTop: title ? 8 : undefined }}>
          <Text
            size={15}
            line="140%"
            color={mode === "dark" ? colors.lightMidGrey : colors.midGrey}
          >
            {excerpt}
          </Text>
        </View>
      )}
    </View>
  );
}

export { SectionTitle };
