import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import { BrowserRouter, Redirect, Route, Switch } from "./components/Router";
import { inject as injectGlobalStyles } from "./globalStyles";
import { AppBranch, AppRoute } from "./routes/AppBranch";
import AppUnsupportedBrowserScreen from "./routes/AppUnsupportedBrowserScreen/AppUnsupportedBrowserScreen";
import { RequireBrowserFeatures } from "./routes/AppUnsupportedBrowserScreen/RequireBrowserFeatures";

smoothscroll.polyfill();

// This inserts our shared, global stylesheet
injectGlobalStyles();

// Build lazy components to split our chunks by branch
// NOTE: CRA (html-webpack-plugin) doesn't support webpackPrefetch yet. Once
// it does, we can use that for prefetching the split chunks
const LazyAppRegisterRoute = React.lazy(() =>
  import(
    /* webpackChunkName: "AppRegisterRoute" */
    "./routes/AppRegisterScreen/AppRegisterRoute"
  ),
);
const LazyUserPortionOfAppBoundary = React.lazy(() =>
  import(
    /* webpackChunkName: "UserPortionOfAppBoundary" */
    "./routes/AppBranch/UserPortionOfAppBoundary"
  ),
);

ReactDOM.render(
  <BrowserRouter>
    <AppBranch>
      <Switch>
        <AppRoute path="/register" exact component={LazyAppRegisterRoute} />
        <Route
          render={() => {
            window.location.href = `${process.env.REACT_APP_MY_DASHBOARD_URL}/role`;
            return null;
          }}
        />
        {/* To support our legacy accounts routes */}
        <Redirect from="/accounts" to="/" />
        <AppRoute
          render={(route: any) => (
            <RequireBrowserFeatures
              features={["for...of"]}
              // We haven't moved to TypeScript app-wide yet
              // @ts-ignore
              fallback={<AppUnsupportedBrowserScreen />}
            >
              <LazyUserPortionOfAppBoundary {...route} />
            </RequireBrowserFeatures>
          )}
        />
      </Switch>
    </AppBranch>
  </BrowserRouter>,
  document.getElementById("root"),
);
