import React, { useContext } from "react";
import { useStorage as createStorage } from "../../utilities/storage";

const StorageContext = React.createContext(null);

const useStorage = () => useContext(StorageContext);

// Reactive wrapper around localStorage
class SetupStorage extends React.Component {
  storage = createStorage(createStorage.LOCAL_STORAGE);

  getItem = key => {
    return this.storage.getItem(key);
  };

  setItem = (key, value) => {
    this.storage.setItem(key, value);
    if (!this.batching && !this.silent) {
      this.update();
    } else if (this.batching) {
      this.callForceUpdateAtEndOfBatch = true;
    }
  };

  removeItem = key => {
    this.storage.removeItem(key);
    if (!this.batching && !this.silent) {
      this.update();
    } else if (this.batching) {
      this.callForceUpdateAtEndOfBatch = true;
    }
  };

  silent = fn => {
    this.silent = true;
    fn();
    this.silent = false;
  };

  batch = fn => {
    this.batching = true;
    fn();
    this.batching = false;
    if (this.callForceUpdateAtEndOfBatch) {
      this.update();
      this.callForceUpdateAtEndOfBatch = false;
    }
  };

  update = () => {
    this.setState(prevState => {
      return { change: prevState.change + 1 };
    });
  };

  state = {
    change: 0,
    getItem: this.getItem,
    setItem: this.setItem,
    removeItem: this.removeItem,
    silent: this.silent,
    batch: this.batch,
  };

  render() {
    return <StorageContext.Provider value={this.state} {...this.props} />;
  }
}

class Storage extends React.Component {
  render() {
    return <StorageContext.Consumer {...this.props} />;
  }
}

export { SetupStorage, Storage, useStorage };
