import React from "react";
import { margin } from "polished";
import { colors } from "../../../../constants";
import { View } from "../../../../components/View";

function SectionDivider({ mode = "dark" }) {
  return (
    <View
      css={{
        ...margin(8, -8, null),
        backgroundColor: mode === "dark" ? "#302f41" : colors.lineGrey,
        height: 1,
      }}
    />
  );
}

export { SectionDivider };
