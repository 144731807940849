import isNil from "lodash/isNil";

export function createScriptLoader(src) {
  let scriptInDom = false;
  let scriptLoaded = false;
  let loadResult;
  let loaders = [];

  function loadScript(onLoad) {
    if (scriptLoaded) {
      onLoad(...loadResult);
      return;
    }

    loaders = [...loaders, onLoad];
    if (scriptInDom) {
      return;
    }

    const s = document.createElement("script");
    s.setAttribute("src", src);
    s.onload = function() {
      loadResult = [null, { src }];
      loaders.forEach(lh => lh(...loadResult));
      scriptLoaded = true;
    };
    s.onerror = function() {
      const error = new Error(`Error loading ${src}`);
      loadResult = [error, { src }];
      loaders.forEach(lh => lh(...loadResult));
      scriptLoaded = true;
    };
    document.head.appendChild(s);
    scriptInDom = true;
  }
  return loadScript;
}

export function changeFavicon(src) {
  const head = document.querySelector("head");
  const favicon = document.querySelector('[rel="shortcut icon"]');

  // Remove old favicon, if it exists
  if (favicon) {
    head.removeChild(favicon);
  }

  // Add new favicon
  const link = document.createElement("link");
  link.rel = "shortcut icon";
  link.href = src;
  head.appendChild(link);
}

export const requestIdleCallback =
  window.requestIdleCallback || window.setTimeout;
export const cancelIdleCallback =
  window.cancelIdleCallback || window.clearTimeout;

export function setManifestHref(href) {
  if (isNil(href)) {
    document.querySelector('[rel="manifest"]').removeAttribute("href");
    return;
  }

  document.querySelector('[rel="manifest"]').setAttribute("href", href);
}

export function setManifestJson(json) {
  if (isNil(json)) {
    document.querySelector('[rel="manifest"]').removeAttribute("href");
    return;
  }

  const stringManifest = JSON.stringify(json);
  const blob = new Blob([stringManifest], { type: "application/javascript" });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('[rel="manifest"]').setAttribute("href", manifestURL);
}

export function copyToClipboard(text) {
  var textArea;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.style.position = "absolute";
    textArea.style.opacity = 0.01;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  createTextArea(text);
  selectText();
  copyToClipboard();
}

export function isIos() {
  const ios =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  return ios;
}

export function getIOSVersion() {
  const versionParts = window.navigator.appVersion.match(
    /OS (\d+)_(\d+)_?(\d+)?/,
  );
  const [, p1, p2, p3] = versionParts;
  return [parseInt(p1, 10), parseInt(p2, 10), parseInt(p3 || 0, 10)];
}

export function iOSHasNativeStatePreservation() {
  const [major, minor] = getIOSVersion();
  return major === 12 && minor >= 2;
}

export function isInStandaloneMode() {
  const isInWebAppiOS = window.navigator.standalone === true;
  const isInWebAppChrome = window.matchMedia("(display-mode: standalone)")
    .matches;
  return isInWebAppiOS || isInWebAppChrome;
}

export function triggerDownload(name, href) {
  const hiddenElement = document.createElement("a");
  hiddenElement.href = href;
  hiddenElement.target = "_blank";
  hiddenElement.download = name;
  hiddenElement.click();
}

export function triggerCsvDownload(name, csvText) {
  const csvFile = new Blob([csvText], { type: "text/csv" });
  const href = window.URL.createObjectURL(csvFile);
  triggerDownload(name + ".csv", href);
}
