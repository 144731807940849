import React from "react";
import { colors } from "../../../../constants";
import { NavTriangle } from "../../icons/NavTriangle";
import { Text } from "../Text";
import { View } from "../View";

function Tooltip({ text, placement, arrowRef, arrowStyle, mode }) {
  return (
    <View
      css={{
        position: "relative",
        background: mode === "dark" ? colors.white : colors.darkPurple,
        width: 175,
        borderRadius: 4,
        padding: 8,
      }}
    >
      <View css={{ lineHeight: 0, textAlign: "left" }}>
        <Text
          line="140%"
          size={12}
          color={mode === "dark" ? colors.darkGrey : colors.white}
        >
          {text}
        </Text>
      </View>
      <View
        css={{
          position: "absolute",
          ...arrowStyle,
          ...(placement === "bottom-start" && { top: -8 }),
          ...(placement === "top-start" && { bottom: -8 }),
          ...(placement === "right" && { left: -8 }),
          ...(placement === "left" && { right: -8 }),
        }}
        ref={arrowRef}
      >
        <View
          css={{
            ...(placement === "bottom-start" && { transform: "rotate(90deg)" }),
            ...(placement === "top-start" && { transform: "rotate(-90deg)" }),
            ...(placement === "right" && { transform: "rotate(0deg)" }),
            ...(placement === "left" && { transform: "rotate(180deg)" }),
          }}
        >
          <NavTriangle
            size={11}
            color={mode === "dark" ? colors.white : colors.darkPurple}
          />
        </View>
      </View>
    </View>
  );
}

export { Tooltip };
