import React from "react";
import { Page } from "../../components/Page";
import { StatusCodeScreenTemplate } from "../AppBranch/StatusCodeScreenTemplate";

function AppUnsupportedBrowserScreen() {
  return (
    <Page
      title="Unsupported Browser — Earnnest"
      backgroundColor="#f9f9f9"
      className="ubuntu stretch"
    >
      <StatusCodeScreenTemplate
        title="We don’t support your browser"
        subtitle="Please switch to a modern browser"
        description={
          <React.Fragment>
            To access this page use one of these browsers: Chrome, Safari,
            Microsoft Edge or Firefox
          </React.Fragment>
        }
        home={false}
      />
    </Page>
  );
}

export default AppUnsupportedBrowserScreen;
export { AppUnsupportedBrowserScreen };
