import React from "react";

const DataBroadcastContext = React.createContext(null);

class SetupDataBroadcast extends React.Component {
  addBroadcast = handler => {
    this.setState(prevState => {
      return { handlers: [...prevState.handlers, handler] };
    });
  };

  removeBroadcast = handler => {
    this.setState(prevState => {
      return {
        handlers: prevState.handlers.filter(h => h !== handler),
      };
    });
  };

  broadcast = (...args) => {
    this.state.handlers.forEach(handler => {
      handler(...args);
    });
  };

  state = {
    handlers: [],
    addBroadcast: this.addBroadcast,
    removeBroadcast: this.removeBroadcast,
    broadcast: this.broadcast,
  };

  render() {
    return <DataBroadcastContext.Provider value={this.state} {...this.props} />;
  }
}

class DataBroadcast extends React.Component {
  static contextType = DataBroadcastContext;

  componentDidMount() {
    if (this.props.onBroadcast) {
      this.context.addBroadcast(this.props.onBroadcast);
    }
  }

  componentWillUnmount() {
    if (this.props.onBroadcast) {
      this.context.removeBroadcast(this.props.onBroadcast);
    }
  }

  render() {
    if (typeof this.props.children === "function") {
      return this.props.children({ broadcast: this.context.broadcast });
    }

    return this.props.children;
  }
}

export { SetupDataBroadcast, DataBroadcast };
